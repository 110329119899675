import { Injectable } from '@angular/core';
import { PlatformName, TipSource } from '../models/tip-source';

@Injectable({
  providedIn: 'root'
})
export class RootService {

  mcLogoFile: string;
  mcLogoLeft: string;
  mcLogoRight: string;
  payTo: TipSource;
  merchUrl: string;
  mailingListUrl?: string;

  constructor() {
    this.mcLogoFile = "MC logo background.png";
    this.mcLogoLeft = "left logo background.png";
    this.mcLogoRight = "right logo background.png";
    this.payTo = {
      userName: "memphisconcrete",
      platform: PlatformName.Paypal,
    };
    this.merchUrl = "memphisconcrete.bandcamp.com/merch";
    this.mailingListUrl = "mailchi.mp/ae1ee808c7d5/memphis-concrete";
   }
}
