import { Pipe, PipeTransform } from '@angular/core';
import { OnlineVenue } from '../models/venue';
import { IconProp } from '@fortawesome/fontawesome-svg-core/index';
import { faTwitch, faYoutube, faFacebook, faInstagram, faBandcamp } from '@fortawesome/free-brands-svg-icons';

@Pipe({
  name: 'venueIcon'
})
export class VenueIconPipe implements PipeTransform {

  transform(value: OnlineVenue | undefined): IconProp {
    switch(value) {
      case OnlineVenue.Twitch: return faTwitch;
      case OnlineVenue.Youtube: return faYoutube;
      case OnlineVenue.Facebook: return faFacebook;
      case OnlineVenue.Instagram: return faInstagram;
      case OnlineVenue.Bandcamp: return faBandcamp;
      default: return faTwitch;
    }

  }

}
