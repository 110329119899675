import { Pipe, PipeTransform } from '@angular/core';
import { DateUtil } from '../util/date-util';

@Pipe({
  name: 'prettyDate'
})
export class PrettyDatePipe implements PipeTransform {

  transform(value: Date): string {
    return DateUtil.getDayOfWeek(value) + ' ' + DateUtil.formatDate(value);
  }

}
