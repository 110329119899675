import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';
import { FestivalHeaderComponent } from '../festival-header/festival-header.component';

@Component({
  selector: 'mc-image-modal',
  templateUrl: './image-modal.component.html',
  styleUrls: ['./image-modal.component.scss']
})
export class ImageModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<FestivalHeaderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string) {}

    ngOnInit(): void {
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
