import { Pipe, PipeTransform } from '@angular/core';
import { TimeRange } from '../models/time-range';
import { DateUtil } from '../util/date-util';

@Pipe({
  name: 'timeRangeCompact'
})
export class TimeRangeCompactPipe implements PipeTransform {

  transform(value: TimeRange): string {
    return DateUtil.formatTimeCompact(value.start) + '-' + DateUtil.formatTimeCompact(value.end);
  }

}
