<p>
    <span class="schedule-text">
        <b>SATURDAY 6/17</b><br />
        <span class="friday-text">
            3:30-4:00 Panic Signals<br />
            4:15-4:45 GODBODYJONES<br />
            5:00-5:30 Stupid Lepton<br />
            5:45-6:15 kavotaman<br />
            6:30-7:00 Slow Blink<br />
            7:15-7:45 CEL SHADE<br />
            8:00-8:30 Chelsea Bridge<br />
            8:45-9:15 XAMBUCA<br />
            9:30-10:00 General Labor
        </span><br />
        <b>SUNDAY 6/18</b><br />
        <span class="saturday-text">
            3:30-4:00 Leolin<br />
            4:15-4:45 Berkay Tok<br />
            5:00-5:30 W1ND0W<br />
            5:45-6:15 Dinosauria<br />
            6:30-7:00 Composer 4<br />
            7:15-7:45 Disaster Trees<br />        
        </span>
    </span>
    <span class="saturday-text electro-performance-text">
        8:00-9:00 <b>Electroacoustic Performance</b> featuring the works of Milton Babbitt, Olly Wilson, Luzilei Aliel, and Indigo Knecht
    </span><br />
<p>