import { Pipe, PipeTransform } from '@angular/core';
import { TimeRange } from '../models/time-range';
import { DateUtil } from '../util/date-util';

@Pipe({
  name: 'timeRange'
})
export class TimeRangePipe implements PipeTransform {

  transform(value: TimeRange): string {
    return DateUtil.formatTime(value.start) + '-' + DateUtil.formatTime(value.end);
  }

}
