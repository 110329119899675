<div>
    <div class="fixed-show-header">
        <p class="header-text">
            EXPERIMENTAL ELECTRONIC MUSIC FESTIVAL<br />
            <span class="special-event">at Crosstown Arts Green Room</span>
        </p>
        <div class="day-grid" fxLayout="row wrap" fxLayoutGap="5px" fxLayoutAlign="center center">
            <ng-container *ngFor="let d of festival.days">
                <mat-card class="date"><span>{{d.timeRange.start | veryPrettyDate}}</span><br /><br />
                    <div class="right-align">{{d.timeRange | timeRangeCompact}} {{d.timeRange.end | timeSignature}}</div>
                </mat-card>
            </ng-container>
        </div>
        <div *ngIf="festival.specialEvents">
            <span class="sub-header-text">WITH SPECIAL EVENTS</span>
            <div fxLayout="row wrap" fxLayoutGap="5px" fxLayoutAlign="center center">
                <ng-container *ngFor="let e of festival.specialEvents">
                    <mat-card class="special-event">
                        <b>{{e.name}}</b><br />
                        <span class="partner-smaller">
                            <span *ngIf="false && (e?.partners?.length ?? 0) > 0">
                                in collaboration with {{e.partners?.join(', ')}}
                                <br />
                            </span>
                            {{e.description}}
                        </span><br />
                        <br />
                        <div>{{e.timeRange.start | veryPrettyDate}}<br />
                        {{e.timeRange | timeRangeCompact}} {{e.timeRange.end | timeSignature}}<br />                        
                        at <b>{{e.location.name}}</b></div>
                    </mat-card>
                </ng-container>
            </div>
        </div>
        <a mat-button focus *ngIf="festival.ticketUrl" class="mc-button" href="{{festival.ticketUrl | url}}">
            TICKETS! <fa-icon *ngIf="festival.ticketUrl" [icon]="ticketIcon"></fa-icon>
        </a>
        <a mat-button focus *ngIf="festival.location.webAddress" class="mc-button" href="{{festival.location.webAddress | url}}">
            Watch on {{festival.location.name}} <fa-icon [icon]="festival.location.externalSite | venueIcon"></fa-icon>
        </a>
        <a mat-button focus *ngIf="festival.scheduleImageUrl" class="mc-button" (click)="openDialog()">
            Detailed Schedule <fa-icon [icon]="calendarIcon"></fa-icon> 
        </a>
        <!-- <a mat-button focus *ngIf="payTo" class="mc-button" href="{{payTo | paymentLink | url}}">
            Donate to the artists <fa-icon *ngIf="payTo" [icon]="payTo.platform | paymentIcon"></fa-icon>
        </a> -->
        <a mat-button focus *ngIf="mailingListUrl" class="mc-button" href="{{mailingListUrl | url}}">
            Mailing List <fa-icon [icon]="envelopeIcon"></fa-icon> 
        </a><br />
        <a mat-button focus *ngIf="festival.samplerCompUrl" class="mc-button" href="{{festival.samplerCompUrl | url}}">
            Artists Sampler Compilation <fa-icon [icon]="festival.samplerCompUrl | linkIcon: 'merch'"></fa-icon> 
        </a>
        <a mat-button focus *ngIf="merchUrl" class="mc-button" href="{{merchUrl | url}}">
            MC Mèrch <fa-icon [icon]="merchUrl | linkIcon: 'merch'"></fa-icon> 
        </a>
    </div>
    <!-- <img src="../../assets/img/{{festival.flyerUrl}}" /> -->
</div>
