import { Pipe, PipeTransform } from '@angular/core';
import { PlatformName } from '../models/tip-source';
import { IconProp } from '@fortawesome/fontawesome-svg-core/index';
import { faPaypal, faVimeo, faApplePay, faGoogleWallet, faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import { faDollarSign, faHryvnia } from '@fortawesome/free-solid-svg-icons';

@Pipe({
  name: 'paymentIcon'
})
export class PaymentIconPipe implements PipeTransform {

  transform(value: PlatformName): IconProp {
    switch(value) {
      case PlatformName.Paypal: return faPaypal;
      case PlatformName.Venmo: return faVimeo;
      case PlatformName.Cashapp: return faDollarSign;
      case PlatformName.ApplePay: return faApplePay;
      case PlatformName.GooglePay: return faGoogleWallet;
      case PlatformName.FacebookPay: return faFacebookSquare;
      case PlatformName.Zelle: return faHryvnia;
      default: return faPaypal;
    }
  }

}
