import { Component, Input, OnInit } from '@angular/core';
import { Show } from '../models/show';
import { TipSource } from '../models/tip-source';
import { RootService } from '../services/root.service';

@Component({
  selector: 'mc-show-header',
  templateUrl: './show-header.component.html',
  styleUrls: ['./show-header.component.scss']
})
export class ShowHeaderComponent implements OnInit {

  @Input()
  theShow!: Show;
  payTo: TipSource;
  merchUrl: string;

  constructor(private _rootService: RootService) {
    this.payTo = _rootService.payTo;
    this.merchUrl = _rootService.merchUrl;
   }

  ngOnInit(): void {
  }

}
