import { Component, OnInit } from '@angular/core';
import { Festival } from '../models/festival';
import { FestivalService } from '../services/festival.service';
import { ArrayUtil } from '../util/array-util';
import { Artist } from '../models/artist';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Component({
  selector: 'mc-festival-layout',
  templateUrl: './festival-layout.component.html',
  styleUrls: ['./festival-layout.component.scss']
})
export class FestivalLayoutComponent implements OnInit {

  festival: Festival;
  genres: string[];
  displayedArtists: Artist[];
  
  selectedGenre: string = '';
  // private selectedGenreBehavior: BehaviorSubject<string> = new BehaviorSubject(this.selectedGenre);
  // selectedGenre$: Observable<string> = this.selectedGenreBehavior.asObservable();

  constructor(private _festivalService: FestivalService) {
    this.festival = this._festivalService.getNextFestival();
    //ArrayUtil.shuffle(this.festival.artists);
    this.festival.artists.sort((a,b) => {
      let aValue = (a.alphabeticalName || a.name).toLowerCase();
      let bValue = (b.alphabeticalName || b.name).toLowerCase();
      return (bValue > aValue) ? -1 : 1;
    });

    this.displayedArtists = this.festival.artists;
    this.genres = this.displayedArtists.map(a => a.genres ?? ['']).flat()
                  .filter((value,index,self) => value != '' && self.indexOf(value) == index).sort();
   }

  ngOnInit(): void {
  }

  filterByGenre(value: string | null) {
    if (value)
      this.displayedArtists = this.festival.artists.filter(a => a.genres?.includes(value));
    else
      this.displayedArtists = this.festival.artists;
    this.selectedGenre = value ?? '';
    // this.selectedGenreBehavior.next(this.selectedGenre);
  }
}
