import { Injectable } from '@angular/core';
import { Show } from '../models/show';
import { PlatformName } from '../models/tip-source';
import { OnlineVenue, VenueType } from '../models/venue';

@Injectable({
  providedIn: 'root'
})
export class ShowService {

  nextShow: Show;

  constructor() {
    this.nextShow = this.getNextShow();
   }

  getNextShow(): Show {
    return {
      startTime: new Date('2021-04-16T21:00:00-05:00'),
      location: {
        name: "Twitch",
        type: VenueType.Online,
        externalSite: OnlineVenue.Twitch,
        webAddress: "www.twitch.tv/memphisconcrete"
      },
      flyerUrl: "4-16-2021.png",
      schedule: 
      [
        {
          timeRange: {
            start: new Date('2021-04-16T21:00:00-05:00'),
            end: new Date('2021-04-16T21:30:00-05:00')
          },
          artist: {
            name: 'Aster',
            imageUrl: '13323641_1436490949710476_492579990659154967_o.jpg',
            infoUrl: 'www.facebook.com/asterlovesyou',
            merchUrl: 'aster.bandcamp.com',
            tipSources: [
              {
                platform: PlatformName.Cashapp,
                userName: '$DominicVanHorn'
              }
            ]
          }
        },
        {
          timeRange: {
            start: new Date('2021-04-16T20:30:00-05:00'),
            end: new Date('2021-04-16T21:00:00-05:00')
          },
          artist: {
            name: 'Paul Vinsonhaler',
            imageUrl: 'IMG_5224.JPG',
            infoUrl: 'www.paulvinsonhaler.com',
            merchUrl: 'paulvinsonhaler.bandcamp.com',
            tipSources: [
              {
                platform: PlatformName.Paypal,
                userName: 'pvnsnhlr'
              }
            ]
          }
        },
        {
          timeRange: {
            start: new Date('2021-04-16T19:30:00-05:00'),
            end: new Date('2021-04-16T20:00:00-05:00')
          },
          artist: {
            name: 'Cable Salad',
            imageUrl: '116101618_3260323334045147_3028559765372203497_n.jpg',
            infoUrl: '',
            merchUrl: '',
            tipSources: [
            ]
          }
        },
        {
          timeRange: {
            start: new Date('2021-04-16T20:00:00-05:00'),
            end: new Date('2021-04-16T20:30:00-05:00')
          },
          artist: {
            name: 'Argiflex',
            imageUrl: 'IMG_4206.JPG',
            infoUrl: 'http://argiflex.com',
            merchUrl: 'argiflex.bandcamp.com/merch',
            tipSources: [
              {
                platform: PlatformName.Paypal,
                userName: 'argiflex'
              }
            ]
          }
        },
        {
          timeRange: {
            start: new Date('2021-04-16T19:00:00-05:00'),
            end: new Date('2021-04-16T19:30:00-05:00')
          },
          artist: {
            name: 'Signals Under Tests',
            imageUrl: 'ricky_memphisconcrete2018.jpg',
            infoUrl: 'signalsundertests.com',
            merchUrl: 'signalsundertests.bandcamp.com/',
            tipSources: [
              {
                platform: PlatformName.Paypal,
                userName: 'signalsundertests'
              }
            ]
          }
        }
      ]
    };
  }
}
