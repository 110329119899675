<div>
    <div class="fixed-show-header">
        <p class="header-text">...presents:</p>
        <span class="date">{{theShow.startTime | prettyDate}}</span><br />
        <a mat-button focus *ngIf="theShow.location.webAddress" class="mc-button" href="{{theShow.location.webAddress | url}}">
            Watch on {{theShow.location.name}} <fa-icon [icon]="theShow.location.externalSite | venueIcon"></fa-icon>
        </a>
        <a mat-button focus *ngIf="payTo" class="mc-button" href="{{payTo | paymentLink | url}}">
            Donate to the artists <fa-icon *ngIf="payTo" [icon]="payTo.platform | paymentIcon"></fa-icon>
        </a><br />
        <a mat-button focus *ngIf="merchUrl" class="mc-button" href="{{merchUrl | url}}">
            MC Mèrch <fa-icon [icon]="merchUrl | linkIcon: 'merch'"></fa-icon> 
        </a>
    </div>
    <img src="../../assets/img/{{theShow.flyerUrl}}" />
</div>
