import { Component, Input, OnInit, Inject } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Festival } from '../models/festival';
import { PlatformName, TipSource } from '../models/tip-source';
import { RootService } from '../services/root.service';
import { IconProp } from '@fortawesome/fontawesome-svg-core/index';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { faEnvelope, faTicket } from '@fortawesome/free-solid-svg-icons';
import { ImageModalComponent } from '../image-modal/image-modal.component';

@Component({
  selector: 'mc-festival-header',
  templateUrl: './festival-header.component.html',
  styleUrls: ['./festival-header.component.scss']
})
export class FestivalHeaderComponent implements OnInit {

  @Input()
  festival!: Festival;
  payTo: TipSource;
  merchUrl: string;
  mailingListUrl?: string;
  calendarIcon: IconProp = faCalendarAlt as IconProp;
  envelopeIcon: IconProp = faEnvelope as IconProp;
  ticketIcon: IconProp = faTicket as IconProp;

  constructor(private _rootService: RootService, public dialog: MatDialog) {
    this.payTo = _rootService.payTo;
    this.merchUrl = _rootService.merchUrl;
    this.mailingListUrl = _rootService.mailingListUrl;
  }

  ngOnInit(): void {
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(ImageModalComponent, {
      width: '500px',
      data: this.festival.scheduleImageUrl
    });

    //dialogRef.afterClosed().subscribe(result => {});
  }
}
