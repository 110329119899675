import { Component, OnInit } from '@angular/core';
import { Show } from '../models/show';
import { TimeSlot } from '../models/time-slot';
import { ShowService } from '../services/show.service';

@Component({
  selector: 'mc-show-layout',
  templateUrl: './show-layout.component.html',
  styleUrls: ['./show-layout.component.scss']
})
export class ShowLayoutComponent implements OnInit {

  show: Show;

  constructor(private _showService: ShowService) {
    this.show = this._showService.getNextShow();
    this.show.schedule.sort((a,b) => b.timeRange.start.getTime() - a.timeRange.start.getTime());
   }

  ngOnInit(): void {
  }

}
