import { Injectable } from '@angular/core';
import { Festival } from '../models/festival';
import { PlatformName } from '../models/tip-source';
import { OnlineVenue, VenueType } from '../models/venue';

@Injectable({
  providedIn: 'root'
})
export class FestivalService {

  nextFestival: Festival;

  constructor() {
    this.nextFestival = this.getNextFestival();
   }

   getNextFestival(): Festival {
    return {
      startTime: new Date('2023-06-17T15:00:00-05:00'),
      location: {
        name: 'Crosstown Arts Green Room',
        type: VenueType.Physical,
        address: '1350 Concourse Ave, #280 - Memphis, TN 38104'
      },
      flyerUrl: '4-16-2021.png',
      ticketUrl: 'www.eventbrite.com/e/2023-memphis-concrete-festival-tickets-635574840457',
      samplerCompUrl: 'memphisconcrete.bandcamp.com/album/2023-artist-sampler',
      scheduleImageUrl: '2021 Schedule.png',
      days: [
        {
          timeRange: {
            start: new Date('2023-06-17T15:00:00-05:00'),
            end: new Date('2023-06-17T22:00:00-05:00')
          },
          schedule: []
        },
        {
          timeRange: {
              start: new Date('2023-06-18T15:00:00-05:00'),
              end: new Date('2023-06-18T21:00:00-05:00')
  
            },
          schedule: []
        }
      ],
      specialEvents: [
        {
          name: 'Halloween III: Season of the Witch',
          timeRange: {
            start: new Date('2023-06-15T19:00:00-05:00'),
            end: new Date('2023-06-15T22:00:00-05:00')
          },
          location: {
            name: 'Crosstown Theater',
            type: VenueType.Physical,
            address: '1350 Concourse Ave, #280 - Memphis, TN 38104'
          },
          description: 'Live score by Natalie Hoffmann/Revenge Body/Robert Traxler',
          price: 10.00,
          partners: ['Crosstown Arts Film Series']
        },
        {
          name: 'Harsh Noise Karaoke',
          timeRange: {
            start: new Date('2023-06-16T18:30:00-05:00'),
            end: new Date('2023-06-16T21:30:00-05:00')
          },
          location: {
            name: 'Lamplighter Lounge',
            type: VenueType.Physical,
            address: '1702 Madison Ave - Memphis, TN 38104'
          },
          price: 0.00,
          isPayWhatYouCan: true,
          description: 'Interactive exhibition where you are the performer!'
        }
      ],
      artists: 
      [
        {
          name: 'Chelsea Bridge',
          alphabeticalName: 'Bridge, Chelsea',
          imageUrl: 'Chelsea Bridge.jpg',
          infoUrl: 'mallorylinehan.wixsite.com/mallorylinehan',
          merchUrl: 'Chelseabridge.bandcamp.com',
          genres: [ 'experimental', 'noise', 'electroacoustic' ],
          bio: 'Moody girl Experimental violin'
        },
        {
          name: 'Berkay Tok',
          alphabeticalName: 'Tok, Berkay',
          imageUrl: 'Berkay_Tok_MC - Berkay Tok.png',
          infoUrl: 'www.soundcloud.com/berkaytok',
          genres: [ 'ambient', 'live coding', 'abstract' ],
          bio: 'Berkay Tok is a sonic artist who creates original compositions on the fly using code, synthesizers, samples and whatever else is available at his disposal that can make noise. His musical spectrum consists of electronic, ambient, noise and experimental music elements shaped into a sonic collage that offers an unconventional listening experience.'
        },
        {
          name: 'kavotaman',
          imageUrl: 'Profile pic-2 - Otávio Manzano Kavakama.png',
          infoUrl: 'otaviomk.com',
          merchUrl: 'kavotaman.bandcamp.com',
          genres: [ 'noise', 'electroacoustic', 'power electronics' ],
          bio: 'kavotaman is a FOSS-based (Free and Open Source Software) noise artist currently based in Memphis, TN. His sonic experimentations are made through Pure Data, Arduino, and Linux.'
        },
        {
          name: 'Stupid Lepton',
          imageUrl: 'PlantGuitar - Karl Erickson.Gif',
          infoUrl: 'http://www.karlhugoerickson.com',
          merchUrl: 'vimeo.com/user4044293',
          genres: [ 'electronic', 'biomusic', 'abstract' ],
          bio: 'Plant-driven, human activated bleeps and sample grinding from a peperomia, a modular synthesizer and a human named Karl.'
        },
        {
          name: 'Dinosauria',
          imageUrl: '0013382917_10.jpg',
          infoUrl: 'linktr.ee/dinosauria901',
          merchUrl: 'Dinosauria.bandcamp.com',
          genres: [ 'ambient', 'post-rock', 'shoegaze' ],
          bio: 'Dinosauria is the brainchild of Malcolm Wright. Dinosauria seeks to explore new sonic landscapes through a unique blend of guitar and ambient sounds. Drawing on a wide range of influences such as Sigur Ros, Explosions In the Sky, and Boris, the project creates a transportive experience that takes listeners on a journey through time and space. Dinosauria\'s music features lush textures, haunting melodies, and intricate rhythms that come together to create a mesmerizing experience.'
        },
        {
          name: 'Slow Blink',
          imageUrl: 'Slow Blink.png',
          infoUrl: 'instagram.com/sloow_blink ',
          merchUrl: 'slow-blink.bandcamp.com/music',
          genres: [ 'ambient', 'electroacoustic', 'musique concrète' ],
          bio: 'Slow Blink is Amanda Haswell\'s dark ambient project. The songs are built around tape loops that primarily feature effect-weathered guitar, piano, and synth. Many have described the sound as being one of nostalgia for memories that sometimes are difficult to pinpoint. Haswell’s latest EP, Imbolc, was self- released digitally February 23. Slow Blink has also found home in the catalogs of Hard Return, Hare’s Breath Records, Bummer Recordings, and Invisible Animals Records. A new split release with Minnesota noise musician, Stomachache, is planned for later this year.'
        },
        {
          name: 'Composer 4',
          imageUrl: 'CCFCA64E-129A-4A6A-AADC-B69CB5B83B6F - C 4.jpeg',
          infoUrl: 'instagram.com/composer.4',
          merchUrl: 'composer4.bandcamp.com',
          genres: [ 'ambient', 'idm', 'soundtrack' ],
          bio: 'There has been 5 albums released under the name Composer 4 since 2018. The music varies in sound but with a common thread falling under the ambient genre. Based out of Memphis, TN, the artist has played a handful of shows in the surrounding area.'
        },
        // {
        //   name: 'Robert Traxler',
        //   alphabeticalName: 'Traxler, Robert',
        //   imageUrl: 'Tatras - Tatras.jpg',
        //   infoUrl: 'www.facebook.com/RobertTraxlerAsAnExperimentalElectronicMusician',
        //   merchUrl: 'traxtronix.bandcamp.com',
        //   genres: [ 'abstract', 'experimental', 'electroacoustic' ],
        //   bio: 'Abstract modular synth; attempts at extending the ideas of early electronic and musique concrète. Now: live score to Halloween II: Season of the Witch with Natalie Hoffmann and Revenge Body.'
        // },
        {
          name: 'Leolin',
          imageUrl: 'LeolinImage - Franklin Doggrell.jpg',
          infoUrl: 'leolin.bandcamp.com',
          genres: [ 'experimental', 'psychedlic', 'electroacoustic' ],
          bio: '\". . . The hardest thing to do is to make something look like it\'s fast, loose and improvised, and get somebody to laugh.\"\n— Abel Ferrara'
        },
        {
          name: 'Panic Signals',
          imageUrl: 'Panic Signals.jpeg',
          genres: [ 'experimental', 'industrial', 'glitch' ],
          bio: 'Panic Signals is an industrial/experimental project that uses glitchy and circuit-bent samplers combined with analog synths to create rich ambient soundscapes that evolve into aggressive industrial rhythms.'
        },
        {
          name: 'GODBODYJONES',
          imageUrl: 'GODBODYJONES.jpeg',
          infoUrl: 'instagram.com/godbodyjones ',
          merchUrl: 'godbodyjones.bandcamp.com',
          genres: [ 'experimental', 'drum and bass', 'glitch' ],
          bio: 'Producer DJ from Memphis 10'
        },
        {
          name: 'XAMBUCA',
          imageUrl: '197E1E9F-863E-4813-8058-E23C6C15403D - Chandra Shukla.jpeg',
          infoUrl: 'xambuca.com ',
          merchUrl: 'xambuca.com/store',
          genres: [ 'ambient', 'electroacoustic', 'industrial' ],
          bio: 'Xambuca is the solo project of Chandra Shukla, founder of Erototox Decodings Xambuca is a multimedia project that strives to emphasize both the sonic and visual worlds free of context, form or genre.'
        },
        {
          name: 'Disaster Trees',
          imageUrl: 'DisasterTrees_CBBPortrait_sq3 - Chris Rueger.jpg',
          infoUrl: 'disastertrees.net',
          merchUrl: 'soundcloud.com/disastertrees',
          genres: [ 'eclectic', 'modular', 'drone' ],
          bio: 'Nashville based Modular Mayhem.'
        },
        {
          name: 'General Labor',
          imageUrl: 'IMG_8086 - Corbin Linebarier.jpeg',
          merchUrl: 'generallabor.bandcamp.com/album/alien-crosstalk',
          genres: [ 'synth punk', 'darkwave', 'post-punk' ],
          bio: 'We will be debuting new material that is a marked transition from the punk set we’ve been playing for a couple years. This set for Concréte is made up almost entirely of open electronic, sequenced based pieces as opposed to the more standard rock band format. The mood and theme is consistently intense but the style is more akin to our film score of The Adventures of Prince Achmed. (this one’s for the silly little edgelords that take bleep bloop cream with their darkwave coffee)'
        },
        {
          name: 'CEL SHADE',
          imageUrl: '23SKIN-LED-mask-superJumbo - CEL SHADE.jpg',
          infoUrl: 'emilionorman.weebly.com',
          merchUrl: 'celshade.bandcamp.com',
          genres: [ 'vaporwave', 'electronic', 'videogame' ],
          bio: 'CEL SHADE is a figment creating experimental soundscapes for you to get lost in. Close your eyes and drift off into your inner conscious and hold on to your seat as you experience a rollercoaster of ear drum crushing sounds and peaceful tranquility.'
        },
        {
          name: 'W1ND0W',
          imageUrl: 'window_promo_23 - Jill Johnson.gif',
          infoUrl: 'vemiotics.com',
          merchUrl: 'wwindoww.bandcamp.com',
          genres: [ 'hyperemo', 'improvisation', 'noise pop' ],
          bio: 'W1ND0W is a hyperemo artist based in Memphis, TN. With 3 years of experience performing and producing, they have established themselves as an experimental and dynamic performer.\n\nIn 2023, W1ND0W released Jilluminati with the mastering services of Suroor Hassan. This was a year-long project exploring cryptic soundscapes and was led up to by various singles of sounds adventuring in the weird. They have since performed at multiple venues including Sorority House in St. Louis and the Hi-Tone in Memphis. W1ND0W has been described as eccentric and sharp and they draw inspiration from Xiu Xiu, Georges Bataille, Charlie XCX, and Miles Davis.'
        }
      ]
    };
  }
}
