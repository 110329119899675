import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faCopy, faRectangleXmark } from '@fortawesome/free-regular-svg-icons';
import { Artist } from '../models/artist';
import { PlatformName, TipSource } from '../models/tip-source';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Component({
  selector: 'mc-artist-block-small',
  templateUrl: './artist-block-small.component.html',
  styleUrls: ['./artist-block-small.component.scss']
})
export class ArtistBlockSmallComponent implements OnInit {
  
  @Input()
  artist: Artist = <Artist>{};

  @Input()
  currentGenre: string = '';

  @Output()
  selectedGenre: EventEmitter<string> = new EventEmitter();

  copyIcon: IconProp = faCopy as IconProp;
  cancelIcon: IconProp = faRectangleXmark as IconProp;

  isBioHidden: boolean = true;
  hasMerchUrl: boolean = false;
  hasInfoUrl: boolean = false;

  constructor() {
  }

  ngOnInit() {
    this.hasMerchUrl = this.doesFieldExist(this.artist.merchUrl);
    this.hasInfoUrl = this.doesFieldExist(this.artist.infoUrl);
   }

  toggleBio() {
    this.isBioHidden = !this.isBioHidden;
  }

  doesFieldExist(value: string | undefined) : boolean {
    return (value ?? false) ? true : false;
  }

  selectGenre(value: string) {
    this.currentGenre = (value != this.currentGenre) ? value : '';
    this.selectedGenre.emit(this.currentGenre);
  }

  hasTipButton(value: TipSource): boolean {
    return (value.platform == PlatformName.Paypal && !value.userName.includes('@'));// || value.platform == PlatformName.Cashapp;
  }
}
