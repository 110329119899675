import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'url'
})
export class UrlPipe implements PipeTransform {

  transform(value: string | undefined): string {
    if (value?.startsWith('https://') || value?.startsWith('http://'))
      return value;
    else if ((value ?? false) ? false : true)
      return '';
    else
      return `https://${value}`;
  }

}
