import { Pipe, PipeTransform } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faFacebookF, faBandcamp, faSoundcloud } from '@fortawesome/free-brands-svg-icons';
import { faInfoCircle, faShoppingBag } from '@fortawesome/free-solid-svg-icons';

@Pipe({
  name: 'linkIcon'
})
export class LinkIconPipe implements PipeTransform {

  transform(value: string | undefined, type?: string): IconProp {
    if (value?.includes('facebook')) return faFacebookF as IconProp;
    else if (value?.includes('bandcamp')) return faBandcamp as IconProp;
    else if (value?.includes('soundcloud')) return faSoundcloud as IconProp;
    else if (type == 'merch') return faShoppingBag as IconProp;
    else return faInfoCircle as IconProp;
  }
}
