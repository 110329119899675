import { Pipe, PipeTransform } from '@angular/core';
import { DateUtil } from '../util/date-util';

@Pipe({
  name: 'veryPrettyDate'
})
export class VeryPrettyDatePipe implements PipeTransform {

  transform(value: Date): string {
    return DateUtil.getDayOfWeek(value) + ' ' + DateUtil.formatPrettyDate(value);
  }

}
