import { Pipe, PipeTransform } from '@angular/core';
import { DateUtil } from '../util/date-util';

@Pipe({
  name: 'timeSignature'
})
export class TimeSignaturePipe implements PipeTransform {

  transform(value: Date): string {
    return DateUtil.getTimeSignature(value);;
  }

}
