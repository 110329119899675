<div fxLayoutAlign="center">
    <div fxLayout="column" fxFlex="0 1 3000px">
      <mc-festival-header [festival]="festival"></mc-festival-header>
      <mat-form-field appearance="fill" style="align-content: left; width: 250px;">
        <mat-label>Fitler by genre</mat-label>
        <mat-select [(value)]="selectedGenre" (valueChange)="filterByGenre($event)">
          <mat-option>-- clear --</mat-option>
          <mat-option *ngFor="let genre of genres" [value]="genre">
            {{genre}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div class="show-grid" fxLayout="row wrap" fxLayoutGap="5px" fxLayoutAlign="center">
        <ng-container *ngFor="let a of displayedArtists">
          <mc-artist-block-small [currentGenre]="selectedGenre" (selectedGenre)="filterByGenre($event)" fxFlex="0 1 calc(25% - 5px)" fxLayoutAlign="stretch" [artist]="a"></mc-artist-block-small>
        </ng-container>
      </div>
    </div>
  </div>