<mat-card>
    <!-- <span class="time">{{artist.timeRange | timeRange}}</span><br />
    <span class="time-signature">{{artist.timeRange.end | timeSignature}}</span><br /> -->
  <span class="artist">{{artist.name}}</span><br />
  <div class="show-grid" fxLayout="row wrap" fxLayoutGap="5px" fxLayoutAlign="left">
    <div class="artist-image">
      <img class="artist-image" src="../../assets/img/{{artist.imageUrl}}"/>
      <div *ngIf="artist.imageCredit"><span class="credit">Image ©{{artist.imageCredit}}</span></div>
    </div>
    <div>
      <div>
        <a mat-button focus class="mc-button" href="{{artist.merchUrl | url}}" [disabled]="!hasMerchUrl">
          Merch <fa-icon [icon]="artist.merchUrl | linkIcon: 'merch'"></fa-icon>
        </a><br />
      </div>
      <div>
        <a mat-button focus class="mc-button" href="{{artist.infoUrl | url}}" [disabled]="!hasInfoUrl">
          Info <fa-icon [icon]="artist.infoUrl | linkIcon"></fa-icon>
        </a><br />
      </div>
      <div>
        <a mat-button focus class="mc-button" (click)="toggleBio()">
          Bio...
        </a>
      </div>
      <!-- <div *ngIf="artist.tipSources">
        <div *ngFor="let s of artist.tipSources">
          <a mat-button focus *ngIf="hasTipButton(s)" class="mc-button" href="{{s | paymentLink | url}}">
            Tips <fa-icon *ngIf="s" [icon]="s.platform | paymentIcon"></fa-icon>
          </a>
          <span *ngIf="!hasTipButton(s)">
            <div *ngIf="s.userName">
              Tip on <b>{{s.platform}}</b>
              <fa-icon [icon]="s.platform | paymentIcon"></fa-icon><br />
              {{s.userName}}
              <button mat-button class="copy-button" title="Copy!" *ngIf="s.userName" ngxClipboard [cbContent]="s.userName">
                <fa-icon [icon]="copyIcon"></fa-icon>
              </button>
            </div>
          </span>
        </div>
      </div> -->
    </div>
  </div>
  <div *ngIf="!isBioHidden">
    <p style="white-space:pre-wrap; margin: 0">{{artist.bio}}</p>
  </div>
  <div>
    <mat-chip-listbox aria-label="Fish selection">
      <mat-basic-chip-option *ngFor="let g of artist.genres" (click)="selectGenre(g)" style="margin-inline: 5px;">
        <span [ngStyle]="{ 'font-weight': (g == currentGenre) ? 'bolder' : 'normal' }">#{{g}}
          <fa-icon [icon]="cancelIcon" *ngIf="g == currentGenre"></fa-icon>
        </span>
      </mat-basic-chip-option>
    </mat-chip-listbox>
  </div>
  </mat-card>