import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog'
import { MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ClipboardModule } from 'ngx-clipboard';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { ArtistBlockComponent } from './artist-block/artist-block.component';
import { ShowLayoutComponent } from './show-layout/show-layout.component';
import { SiteHeaderComponent } from './site-header/site-header.component';
import { ShowHeaderComponent } from './show-header/show-header.component';

import { ShowService } from './services/show.service';
import { PaymentIconPipe } from './pipes/payment-icon.pipe';
import { PaymentLinkPipe } from './pipes/payment-link.pipe';
import { ExternalLinkDirective } from './external-link.directive';
import { LinkIconPipe } from './pipes/link-icon.pipe';
import { TimeRangePipe } from './pipes/time-range.pipe';
import { TimeSignaturePipe } from './pipes/time-signature.pipe';
import { PrettyDatePipe } from './pipes/pretty-date.pipe';
import { VenueIconPipe } from './pipes/venue-icon.pipe';
import { UrlPipe } from './pipes/url.pipe';
import { ArtistBlockSmallComponent } from './artist-block-small/artist-block-small.component';
import { FestivalHeaderComponent } from './festival-header/festival-header.component';
import { FestivalLayoutComponent } from './festival-layout/festival-layout.component';
import { TimeRangeCompactPipe } from './pipes/time-range-compact.pipe';
import { VeryPrettyDatePipe } from './pipes/very-pretty-date.pipe';
import { ImageModalComponent } from './image-modal/image-modal.component';

@NgModule({
  declarations: [
    AppComponent,
    ArtistBlockComponent,
    ShowLayoutComponent,
    PaymentIconPipe,
    PaymentLinkPipe,
    ExternalLinkDirective,
    LinkIconPipe,
    TimeRangePipe,
    TimeSignaturePipe,
    SiteHeaderComponent,
    ShowHeaderComponent,
    PrettyDatePipe,
    VenueIconPipe,
    UrlPipe,
    ArtistBlockSmallComponent,
    FestivalHeaderComponent,
    FestivalLayoutComponent,
    TimeRangeCompactPipe,
    VeryPrettyDatePipe,
    ImageModalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatButtonModule,
    MatDialogModule,
    MatGridListModule,
    FontAwesomeModule,
    FlexLayoutModule,
    ClipboardModule,
    MatSelectModule,
    MatChipsModule
  ],
  providers: [ShowService],
  bootstrap: [AppComponent]
})
export class AppModule {
    constructor(library: FaIconLibrary) {
      library.addIcons();
    }
 }
