export class Venue {
    name!: string;
    type!: VenueType;
    externalSite?: OnlineVenue;
    webAddress?: string;
    address?: string;
}

export enum VenueType {
    Physical,
    Online
}

export enum OnlineVenue {
    Twitch,
    Youtube,
    Facebook,
    Instagram,
    Bandcamp
}