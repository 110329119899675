import { Component, Input, OnInit } from '@angular/core';
import { RootService } from '../services/root.service';

@Component({
  selector: 'mc-site-header',
  templateUrl: './site-header.component.html',
  styleUrls: ['./site-header.component.scss']
})
export class SiteHeaderComponent implements OnInit {

  imageFile: string;
  // leftImageFile: string;
  rightImageFile: string;
  numberArray: number[] = new Array(250).fill(null).map((_, i) => i + 1);

  constructor(_rootService: RootService) {
    this.imageFile = _rootService.mcLogoFile;
    // this.leftImageFile = _rootService.mcLogoLeft;
    this.rightImageFile = _rootService.mcLogoRight;
   }

  ngOnInit(): void {
  }

  // makeArray(n: number): number[] {
  //   return [...Array(n).keys()];
  // }
}
