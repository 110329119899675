import { Component, Input, OnInit } from '@angular/core';
import { PlatformName, TipSource } from '../models/tip-source';
import { TimeSlot } from '../models/time-slot';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCopy } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'mc-artist-block',
  templateUrl: './artist-block.component.html',
  styleUrls: ['./artist-block.component.scss']
})
export class ArtistBlockComponent implements OnInit {

  @Input()
  artist: TimeSlot = <TimeSlot>{};

  copyIcon: IconProp = faCopy as IconProp;

  constructor() {
  }

  ngOnInit() {
  }

  hasTipButton(value: TipSource): boolean {
    return (value.platform == PlatformName.Paypal && !value.userName.includes('@'));// || value.platform == PlatformName.Cashapp;
  }
}
