<mat-card>
  <span class="time">{{artist.timeRange | timeRange}}</span><br />
  <span class="time-signature">{{artist.timeRange.end | timeSignature}}</span><br />
  <img class="artist-image" src="../../assets/img/{{artist.artist.imageUrl}}"/><br />
<span class="artist">{{artist.artist.name}}</span><br />
<a mat-button focus *ngIf="artist.artist.merchUrl" class="mc-button" href="{{artist.artist.merchUrl | url}}">
  Merch <fa-icon [icon]="artist.artist.merchUrl | linkIcon: 'merch'"></fa-icon>
</a>
<a mat-button focus *ngIf="artist.artist.infoUrl" class="mc-button" href="{{artist.artist.infoUrl | url}}">
  Info <fa-icon [icon]="artist.artist.infoUrl | linkIcon"></fa-icon>
</a><br />
<div *ngIf="artist.artist.tipSources">
  <div *ngFor="let s of artist.artist.tipSources">
    <a mat-button focus *ngIf="hasTipButton(s)" class="mc-button" href="{{s | paymentLink | url}}">
      Tips <fa-icon *ngIf="s" [icon]="s.platform | paymentIcon"></fa-icon>
    </a>
    <span *ngIf="!hasTipButton(s)">
      <div *ngIf="s.userName">
        Tip on <b>{{s.platform}}</b> 
        <fa-icon [icon]="s.platform | paymentIcon"></fa-icon>&nbsp;
        {{s.userName}}
        <button mat-button class="copy-button" title="Copy!" *ngIf="s.userName" ngxClipboard [cbContent]="s.userName">
          <fa-icon [icon]="copyIcon"></fa-icon>
        </button>
      </div>
    </span>
  </div>
</div>
</mat-card>