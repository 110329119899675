import { Pipe, PipeTransform } from '@angular/core';
import { PlatformName, TipSource } from '../models/tip-source';

@Pipe({
  name: 'paymentLink'
})
export class PaymentLinkPipe implements PipeTransform {

  transform(value: TipSource): string {
    switch(value.platform) {
      case PlatformName.Paypal: return 'paypal.me/' + value.userName;
      case PlatformName.Venmo: return 'faVimeo';
      case PlatformName.Cashapp: return 'faDollarSign';// 'cash.app/' + value.userName;
      case PlatformName.ApplePay: return 'faApplePay';
      case PlatformName.GooglePay: return 'faGoogleWallet';
      case PlatformName.FacebookPay: return 'faFacebookSquare';
      case PlatformName.Zelle: return 'faYenSign';
      default: return 'faPaypal';
    }
  }

}
