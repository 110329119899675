export class TipSource {
    userName!: string
    platform!: PlatformName;
}

export enum PlatformName {
    Paypal = "Paypal",
    Venmo = "Venmo",
    Cashapp = "Cashapp",
    ApplePay = "ApplePay",
    GooglePay = "GooglePay",
    FacebookPay = "FacebookPay",
    Zelle = "Zelle"
}