export module DateUtil {

    export function formatTime(value: Date): string {
        return format12HourTime(value) + ':' + formatMinutes(value.getMinutes());
    }
    
    export function formatTimeCompact(value: Date): string {
        return format12HourTime(value) + (value.getMinutes() == 0 ? '' : (':' + formatMinutes(value.getMinutes())));
    }

    export function formatDate(value: Date): string {
        return (value.getMonth() + 1) + '/' + value.getDate() + '/' + value.getFullYear().toString().slice(-2);
    }

    export function formatPrettyDate(value: Date): string {
        return (value.toLocaleString('default', { month: 'long' })) + ' ' + value.getDate();
    }

    export function getTimeSignature(value: Date): string {
        var hour = value.getHours();
        return (hour < 12 ? 'AM' : 'PM') + ' Central';
    }

    function format12HourTime(value: Date): string {
        var time: number = value.getHours() % 12;
        if (time == 0) time = 12;
        return time + '';
    }

    function formatMinutes(value: number) {
        var prefix: string = '';
        if (value < 10)
            prefix = '0';
        return prefix + value;
    }

    export function getDayOfWeek(value: Date): string {
        return DayOfWeek[value.getDay()];
    }

    export enum DayOfWeek {
        Sunday,
        Monday,
        Tuesday,
        Wednesday,
        Thursday,
        Friday,
        Saturday
    }
}
